/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Fragment, useEffect, useState } from 'react';

import { createUseStyles } from 'react-jss';
import screenfull from 'screenfull';

import DetailPage from './components/DetailPage';
import HomePage from './components/HomePage';
import ProductList from './components/ProductList';
import ChairList from './components/ChairList';
import isMobile from 'is-mobile';

const useStyles = createUseStyles({
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    // background: 'rgb(243,234,224)',
    // background:
    //   'linear-gradient(180deg, rgba(243,234,224,1) 10%, rgba(255,255,255,0) 100%)',
  },
  label: {
    fontFamily: 'GothamProLight',
    fontWeight: 300,
    color: '#44464b',
    fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
  },
});

function App() {
  const classes = useStyles();
  const [page, setPage] = useState('home');
  const [active, setActive] = useState('');
  const [isFullscreenState, setFullScreenState] = useState(false);
  useEffect(() => {
    if (active) {
      console.log('active:: ', active);
      setPage('detailPage');
    }
  }, [active]);

  const [hearts, setHearts] = useState([]);

  const base = `/assets/${active}_out/${active}`;
  const src = `${base}.gltf`;
  const poster = `${base}.png`;
  const iosSrc = `${base}.usdz`;

  const labels = [
    {
      title: 'Folia',
      description: 'arm chair',
      src: '/assets/FOL55_out/FOL55.png',
      alt: 'FOL55',
    },
    {
      title: 'Folia',
      description: 'rocking chair',
      src: '/assets/FOLR_out/FOLR.png',
      alt: 'FOLR',
    },
    {
      title: 'Folia',
      description: 'footrest',
      src: '/assets/FOLF_out/FOLF.png',
      alt: 'FOLF',
    },
    {
      title: 'Folia',
      description: 'relax chair',
      src: '/assets/FOL77_out/FOL77.png',
      alt: 'FOL77',
    },
    {
      title: 'Folia',
      description: 'arm chair',
      src: '/assets/FOL55_out/FOL55.png',
      alt: 'FOL55',
    },
    {
      title: 'Folia',
      description: 'rocking chair',
      src: '/assets/FOLR_out/FOLR.png',
      alt: 'FOLR',
    },
    {
      title: 'Folia',
      description: 'footrest',
      src: '/assets/FOLF_out/FOLF.png',
      alt: 'FOLF',
    },
    {
      title: 'Folia',
      description: 'relax chair',
      src: '/assets/FOL77_out/FOL77.png',
      alt: 'FOL77',
    },
    {
      title: 'Folia',
      description: 'arm chair',
      src: '/assets/FOL55_out/FOL55.png',
      alt: 'FOL55',
    },
    {
      title: 'Folia',
      description: 'rocking chair',
      src: '/assets/FOLR_out/FOLR.png',
      alt: 'FOLR',
    },
    {
      title: 'Folia',
      description: 'footrest',
      src: '/assets/FOLF_out/FOLF.png',
      alt: 'FOLF',
    },
    {
      title: 'Folia',
      description: 'relax chair',
      src: '/assets/FOL77_out/FOL77.png',
      alt: 'FOL77',
    },
  ];
  const descriptions = {
    FOL55: {
      title: 'FOLIA ARM CHAIR',
      description: 'coated stainless steel',
    },
    FOLR: {
      title: 'FOLIA ROCKING CHAIR',
      description: 'coated stainless steel',
    },
    FOLF: {
      title: 'FOLIA FOOTREST',
      description: 'coated stainless steel',
    },
    FOL77: {
      title: 'FOLIA RELAX CHAIR',
      description: 'coated stainless steel',
    },
  };

  // screenfull.on('change', () => {
  //   console.log('changed', screenfull.isFullscreen);
  //   // eslint-disable-next-line no-restricted-globals
  //   // screen.orientation.lock('landscape');

  //   setFullScreenState(screenfull.isFullscreen);
  // });
  console.log('page', page, isFullscreenState);
  // useEffect(() => {
  //   console.log('is', isMobile());
  //   if (isMobile()) {
  //     if (page === 'home' && isFullscreenState) {
  //       screenfull.exit();
  //     }
  //     if (page !== 'home' && !isFullscreenState) {
  //       screenfull.request();
  //     }
  //   }
  // }, [isFullscreenState, page]);
  return (
    <Fragment>
      {page === 'home' && (
        <HomePage
          setPage={() => {
            setPage('productList');
          }}
        />
      )}
      {page === 'detailPage' && (
        <DetailPage
          poster={poster}
          name={active}
          title={descriptions[active].title}
          description={descriptions[active].description}
          setPage={setPage}
          src={src}
          iosSrc={iosSrc}
          heart={hearts.includes(descriptions[active].title)}
          toggleHeart={() => {
            const value = descriptions[active].title;
            setHearts([
              ...(hearts.includes(value)
                ? hearts.filter((heart) => heart !== value)
                : [...hearts, value]),
            ]);
          }}
        />
      )}
      {page === 'productList' && <ProductList setPage={setPage} />}
      {page === 'chairList' && (
        <ChairList setPage={setPage} setActive={setActive} chairs={labels} />
      )}
    </Fragment>
  );
}

export default App;
