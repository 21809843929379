import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 20,
    width: '100%',
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
  },
});

const TopBar = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick}>
      <div>
        <img src={'/assets/home.png'} alt={'home'} />{' '}
      </div>
      <div>
        <img src={'/assets/overview.png'} alt={'overview'} />{' '}
      </div>
      <div>
        <img src={'/assets/profile.png'} alt={'profile'} />{' '}
      </div>
    </div>
  );
};

export default TopBar;
