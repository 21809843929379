import { createUseStyles } from 'react-jss';
import TopBar from './TopBar';
import BottomBar from './BottomBar';

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    paddingTop: '5px',
    fontSize: '21px',
    fontFamily: 'GothamPro',
    alignSelf: 'baseline',
    margin: '16px 5%',
    fontWeight: 'bold'
  },
  productContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'baseline',
    alignContent: 'flex-start',
    margin: '0 5% 60px',
    height: 'calc(100% - 132px)',
    overflow: 'scroll',
  },
  imageContainer: {
    margin: '5px',
    width: '45%',
    height: '35%',
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: '#EFEFEF',
    flexGrow: 1,
    '& img': {
      height: '68%',
    },
    '& div:first-of-type': {
      marginTop: 10,
      fontWeight: 'bold'
    },
  },
});

const ChairList = ({ setPage, setActive, chairs }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar onClick={() => setPage('productList')} />
      <div className={classes.title}>Chairs</div>
      <div className={classes.productContainer}>
        {chairs.map(({ alt, src, title, description }) => {
          return (
            <div
              className={classes.imageContainer}
              onClick={() => setActive(alt)}
            >
              <img src={src} alt={alt} />
              <div>{title}</div>
              <div>{description}</div>
            </div>
          );
        })}
      </div>
      <BottomBar />
    </div>
  );
};

export default ChairList;
