import ModelLogo from './ModelLogo';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles({
  root: {
    backgroundColor: '#231f20',
    fontFamily: 'GothamPro',
  },
  grid: {
    color: '#b68647',
    display: 'grid',
    placeItems: 'center',
    height: '100vh',
  },
  button: {
    color: '#b68647',
    padding: '18px 21px',
    width: 'auto',
    border: '1px solid #b68647',
    cursor: 'pointer',
  },
});
const ProductPage = ({ poster, setPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.grid}>
        <img src={'/assets/logo.png'} alt={'logo'} />
        <div className={classes.button} onClick={setPage}>
          <ModelLogo /> Open model viewer
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
