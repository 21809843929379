import { Fragment, useEffect, useRef, useState } from 'react';
import TopBar from './TopBar';
import BottomBar from './BottomBar';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import Controls from './Controls';
import ARLogo from './ar.svg';

const useStyles = createUseStyles({
  root: {
    // backgroundColor: '#231f20',
    fontFamily: 'GothamPro',
    padding: '5px 25px 25px 25px',
    height: 'calc(100vh - 45px - 68px)',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: 'auto',
  },
  button: {
    color: '#b68647',
    padding: '18px 21px',
    width: 'auto',
    border: '1px solid #b68647',
    cursor: 'pointer',
  },
  labelContainer: {
    display: 'none',
    // flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: '25px',
    paddingTop: '50px',
    // background: 'rgb(243,234,224)',
    // background:
    //   'linear-gradient(180deg, rgba(243,234,224,1) 10%, rgba(255,255,255,0) 100%)',
  },
  isPresenting: {
    display: 'block',
  },
  labelInAr: {
    fontFamily: 'GothamProLight',
    fontWeight: 300,
    color: 'white',
    fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontFamily: 'GothamPro',
    fontWeight: 300,
    color: '#44464b',
    fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
  },
  subLabel: {
    fontFamily: 'GothamProLight',
    fontWeight: 300,
    color: '#44464b',
    // fontSize: '21px',
    lineHeight: 1.2,
    letterSpacing: '1pt',
    paddingBottom: '5px',
  },
  modelViewer: {
    // backgroundColor: '#eee',
    // '--poster-color': '#eee',
    width: '100%',
    flexGrow: 1,
    maxHeight: 'calc(100vw - 50px)',
    // height: '300px',
    // '@media (min-width: 1024px)': {
    //   minHeight: '50vh',
    // },
    // height: '100vh',
  },
  imgContainer: {},
  img: {
    width: '75%',
    margin: 'auto',
    display: 'block',
  },
  arButton: {
    display: 'none',
  },
  customArButton: {
    backgroundColor: 'black',
    padding: '16px 0px 10px 0px',
    width: '25%',
    // cursor: 'pointer',
    // // textAlign: 'center',
    // margin: 'auto',
    // display: 'inline-block',
    maxWidth: '100px',
    textAlign: 'center',
    display: 'block',
  },
  findDealerButton: {
    color: '#b68647',
    padding: '18px 21px',
    // width: '66%',
    border: '1px solid #b68647',
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  exitButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '16px',
    left: '16px',
    width: '40px',
    height: '40px',
    boxSizing: 'border-box',
  },
});

const colors = [
  {
    name: 'Anthracite',
    colorString: '0.067, 0.059, 0.063, 1.0',
    src: 'COATED_ALU_ZU.jpg',
  },
  {
    name: 'White',
    colorString: '1.0, 1.0, 1.0, 1.0',
    src: 'COATED_ALU_WR.jpg',
  },
  {
    name: 'Bronze',
    colorString: '0.149, 0.137, 0.208, 1.0',
    src: 'COATED_ALU_BR.jpg',
  },
  {
    name: 'Mushroom',
    colorString: '0.753, 0.741, 0.675, 1.0',
    src: 'COATED_ALU_MR.jpg',
  },
  {
    name: 'Olive Green',
    colorString: '0.553, 0.616, 0.576, 1.0',
    src: 'COATED_ALU_OL.jpg',
  },
  {
    name: 'Sky Blue',
    colorString: '0.557, 0.678, 0.749, 1.0',
    src: 'COATED_ALU_SB.jpg',
  },
];

const DetailPage = ({
  src,
  iosSrc,
  title,
  name,
  poster,
  setPage,
  description,
  heart,
  toggleHeart,
}) => {
  const modelRef = useRef(null);
  const classes = useStyles();
  const [color, setColor] = useState('Anthracite');
  const [isPresenting, setIsPresenting] = useState(false);
  const [isPlaced, setIsPlaced] = useState(false);
  console.log('color', color);
  const activeColor = colors.find(({ name }) => name === color);

  console.log('active', activeColor.colorString);
  useEffect(() => {
    console.log('effect', activeColor.colorString);
    if (activeColor.colorString && modelRef.current && modelRef.current.model) {
      const modelViewer = modelRef.current;
      console.log('modelViewer', modelViewer.model);

      const color = activeColor.colorString
        .split(',')
        .map((numberString) => parseFloat(numberString));

      console.log('Changing color to: ', color);
      const { materials } = modelViewer.model;

      materials.forEach((material) => {
        console.log('material', material);
        material.pbrMetallicRoughness.setBaseColorFactor(color);
      });
    }
  }, [activeColor.colorString, color]);

  useEffect(() => {
    console.log('modelRef', modelRef.current);
    // document
    //   .querySelector('#model-viewer')
    modelRef.current.addEventListener('ar-status', (event) => {
      console.log('event', event.detail.status);
      console.log('button', document.querySelector('.exit-webxr-ar-button'));
      if (event.detail.status === 'failed') {
        const error = document.querySelector('#error');
        error.classList.remove('hide');
        error.addEventListener('transitionend', (event) => {
          error.classList.add('hide');
        });
      }

      if (event.detail.status === 'session-started') {
        setIsPresenting(true);
      }
      if (event.detail.status === 'not-presenting') {
        setIsPresenting(false);
        setIsPlaced(false);
      }
      if (event.detail.status === 'object-placed') {
        setIsPlaced(true);
      }
    });
  });

  return (
    <Fragment>
      <TopBar onClick={() => setPage('chairList')} />

      <div className={classes.root}>
        <div className={classes.titleRow}>
          <div className={classes.label}>{title}</div>
          <img
            onClick={toggleHeart}
            src={`/assets/${heart ? 'heartfilled' : 'heart'}.svg`}
            alt={'heart'}
          />
        </div>

        <div className={classes.subLabel}>{description}</div>
        <model-viewer
          ref={modelRef}
          src={src}
          ios-src={iosSrc}
          // environment-image={"/assets/color/COATED_ALU_SB.jpg"}
          poster={poster}
          shadow-intensity="1"
          ar
          ar-modes="webxr scene-viewer quick-look"
          ar-scale="fixed"
          camera-controls
          alt="A 3D model"
          class={classes.modelViewer}
          camera-orbit={'-0.758802552117009rad 1.235671094289293rad auto'}
        >
          <div
            className={clsx(
              classes.labelContainer,
              isPresenting && classes.isPresenting,
            )}
          >
            <h1 className={classes.labelInAr}>{title}</h1>
          </div>
          <button className={classes.arButton} slot="ar-button" id="ar-button">
            View in your space
          </button>
          <a
            className={classes.exitButton}
            slot="exit-webxr-ar-button"
            id="exit-webxr-ar-button"
          >
            <img src={'/assets/exit.svg'} alt={'exit'} />
          </a>
        </model-viewer>
        <div className={classes.subLabel}>
          <b>Frame: </b>({activeColor.name})
        </div>
        <Controls colors={colors} setColor={({ name }) => setColor(name)} />
        <div className={classes.buttonContainer}>
          <div className={classes.findDealerButton}>Find a dealer near you</div>
          <div
            onClick={() => {
              document.getElementById('ar-button').click();
            }}
            className={classes.customArButton}
            id="custom-ar-button"
          >
            <img src={ARLogo} alt="AR Logo" />
          </div>
        </div>
      </div>
      <BottomBar />
    </Fragment>
  );
};
// <img className={classes.img} src={poster} alt={'poster'} />

export default DetailPage;
