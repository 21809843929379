import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles({
  root: {
    padding: '25px 25px 5px 25px',
  },
});

const TopBar = ({ onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick}>
      <img src={'/assets/back.png'} alt={'back'} />{' '}
    </div>
  );
};

export default TopBar;
