import { Fragment } from 'react';
import { createUseStyles } from 'react-jss';
import TopBar from './TopBar';
import BottomBar from './BottomBar';

const useStyles = createUseStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    paddingTop: '5px',
    fontSize: '21px',
    fontFamily: 'GothamPro',
    alignSelf: 'baseline',
    margin: '16px 5%',
    fontWeight: 'bold',
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 5% 60px',
    height: 'calc(100% - 132px)',
    overflowY: 'scroll',
  },
  imageContainer: {
    margin: '5px',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '& div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: 'xx-large',
      color: 'white',
    },
  },
});

const ProductList = ({ poster, setPage }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar onClick={() => setPage('home')} />
      <div className={classes.title}>Products</div>
      <div className={classes.productContainer}>
        <div
          className={classes.imageContainer}
          onClick={() => setPage('chairList')}
        >
          <div>Chairs</div>
          <img src="/assets/products/chairs.png" alt="chairs" />
        </div>
        <div
          className={classes.imageContainer}
          onClick={() => setPage('chairList')}
        >
          <div>Benches</div>
          <img src="/assets/products/benches.png" alt="benches" />
        </div>
        <div
          className={classes.imageContainer}
          onClick={() => setPage('chairList')}
        >
          <div>Sun loungers</div>
          <img src="/assets/products/sun-loungers.png" alt="sun loungers" />
        </div>
        <div
          className={classes.imageContainer}
          onClick={() => setPage('chairList')}
        >
          <div>Tables</div>
          <img src="/assets/products/tables.png" alt="tables" />
        </div>
        <div
          className={classes.imageContainer}
          onClick={() => setPage('chairList')}
        >
          <div>Lounge sets</div>
          <img src="/assets/products/lounge-sets.png" alt="lounge sets" />
        </div>
        <div
          className={classes.imageContainer}
          onClick={() => setPage('chairList')}
        >
          <div>Other</div>
          <img src="/assets/products/other.png" alt="other" />
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default ProductList;
